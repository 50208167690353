import React from "react";
import Row from "starkeydigital/React/Components/row";
import Layout from "../../components/layout";
import Column from "starkeydigital/React/Components/column";
import styled from "@emotion/styled";
import AnimateUp from "starkeydigital/React/Components/AnimateUp";
interface props {}
const OpenLeadershipProgrammes: React.FC<props> = (props) => {
  const SubHeader = styled.div`
    font-family: Averta CY;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 31px;
    color: #02578c;
    margin-top: 20px;
  `;

  return (
    <Layout
      titleText="Open Programmes"
      titleText2=""
      slogan="We run open programmes bringing smaller groups of participants together from firms who do not have the scale to run their own programme."
    >
      {/* <HeaderDivideTextLeft
        style={{marginTop: "115px"}}
        column="col-md-10"
        header="Open Programmes"
        text="Integrated programmes where international research and exceptional coaching combine to give you academic rigour, personalised support and challenge. Group coaching, plenary sessions and networking focussed on the challenges and opportunities in the workplace rather than artificial case studies."
      />

      <Row>
        <div className="col mt-4">
          <ContactButton>Ready to work together?</ContactButton>
        </div>
      </Row> */}

      <Row>
        <Column class="col-md-10">
          <AnimateUp>
            <h1 style={{textAlign: "left", marginTop: "100px"}}>
              Step Forward Development Programmes
            </h1>
          </AnimateUp>

          <AnimateUp>
            <SubHeader>
              Designed for high performing senior executives in professional
              services firms and global organisations. Internationally renowned.
            </SubHeader>
          </AnimateUp>

          <AnimateUp>
            <div className="divider" style={{margin: "35px 0"}} />
          </AnimateUp>
          <AnimateUp>
            <p style={{textAlign: "left"}}>
              The Step Forward Development Programmes focus on helping
              individuals to become more effective in the workplace and to move
              their careers forward, either towards partner or senior partner
              levels in professional services organisations or at senior leader
              level within global organisations.
            </p>
          </AnimateUp>
        </Column>
      </Row>
    </Layout>
  );
};
export default OpenLeadershipProgrammes;
